<span class="p-input-icon-right w-full">
  <span class="pi pi-search" style="z-index: 1"></span>
  <p-autoComplete
    #senderSearch
    id="searchSenderAuto"
    inputId="senderSearch"
    [title]="title()"
    [ariaLabel]="ariaLabel()"
    [ariaLabelledBy]="ariaLabelledBy()"
    [suggestions]="suggestionsSender()"
    field="name"
    [scrollHeight]="senderSearch.suggestions?.length > 3 ? '200px' : '150px'"
    styleClass="w-full"
    inputStyleClass="inputfield w-full"
    (completeMethod)="autoCompleteSenderSearch($event)"
    (onSelect)="senderSelected($event.value)"
    (onShow)="scrollOneDown($event)"
  >
    <ng-template pTemplate="footer">
      <hr />
      <div class="text-left ml-3">
        <div class="mt-1 help-text" translate>enrollment.DidntFind</div>
        <p class="help-text m-0 mb-3" translate>
          <a class="cursor-pointer underline" role="button" (click)="sendATipClicked($event)" translate>enrollment.SendUsATip</a>
        </p>
      </div>
    </ng-template>
  </p-autoComplete>
</span>
