/**
 * Enum defining language
 */
export enum BiLanguageId {
  DK = 1,
  SE = 2,
  EN = 3,
  FI = 4,
  NO = 5
}

export type LanguageCodes = "se" | "da" | "no" | "fi" | "en";

export function getLanguageTranslationKeyByLanguageId(id: BiLanguageId) {
  switch (id) {
    case BiLanguageId.DK:
      return "shared.Danish";
    case BiLanguageId.SE:
      return "shared.Swedish";
    case BiLanguageId.EN:
      return "shared.English";
    case BiLanguageId.FI:
      return "shared.Finnish";
    case BiLanguageId.NO:
      return "shared.Norwegian";
  }
}

export function getCountryTranslationKeyByCountryId(id: BiCountryId) {
  switch (id) {
    case BiCountryId.DK:
      return "shared.Denmark";
    case BiCountryId.SE:
      return "shared.Sweden";
    case BiCountryId.FI:
      return "shared.Finland";
    case BiCountryId.NO:
      return "shared.Norway";
  }
  return null;
}

/**
 * Enum defining country
 */

export enum BiCountryId {
  Default = 0,
  DK = 1,
  SE = 2,
  EN = 3,
  FI = 4,
  NO = 5,
  DE = 6
}

// remember to add new shared numbers to backend as well
export const sharedInfobibNumbersDK: string[] = ["4592458888"];
export const sharedInfobibNumbersSE: string[] = [];
export const sharedInfobibNumbersEN: string[] = [];
export const sharedInfobibNumbersNO: string[] = ["4721607928"];
export const sharedInfobibNumbersFI: string[] = [];

export const sharedInfobibNumbers: string[] = [...sharedInfobibNumbersDK, ...sharedInfobibNumbersSE, ...sharedInfobibNumbersEN, ...sharedInfobibNumbersNO, ...sharedInfobibNumbersFI];

export function getSharedInfobibNumbersByLanguageCode(countryId: BiCountryId): string[] {
  switch (countryId) {
    case BiCountryId.DK:
      return sharedInfobibNumbersDK;
    case BiCountryId.SE:
      return sharedInfobibNumbersSE;
    case BiCountryId.EN:
      return sharedInfobibNumbersEN;
    case BiCountryId.NO:
      return sharedInfobibNumbersNO;
    case BiCountryId.FI:
      return sharedInfobibNumbersFI;
    default:
      return sharedInfobibNumbersDK;
  }
}

export function getCountryByLanguageCode(languageCode: LanguageCodes): BiCountryId {
  switch (languageCode) {
    case "da":
      return BiCountryId.DK;
    case "se":
      return BiCountryId.SE;
    case "en":
      return BiCountryId.EN;
    case "no":
      return BiCountryId.NO;
    case "fi":
      return BiCountryId.FI;
    default:
      return BiCountryId.DK;
  }
}

export function getLanguageIdByLanguageCode(languageCode: LanguageCodes) {
  switch (languageCode) {
    case "da":
      return BiLanguageId.DK;
    case "se":
      return BiLanguageId.SE;
    case "en":
      return BiLanguageId.EN;
    case "no":
      return BiLanguageId.NO;
    case "fi":
      return BiLanguageId.FI;
    default:
      return BiLanguageId.DK;
  }
}
